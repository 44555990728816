import axiosInstance from "./axiosConfig";

const adServices = {
    getAdByStream: async (id) => {
        try {
            const response = await axiosInstance.get(`/search/ad/${id}`);
            return response.data.ad;
        } catch (error) {
            return { success: false, error: 'Помилка при отриманні оголошення' };
        }
    },
};

export default adServices;