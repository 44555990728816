import axiosInstance from "./axiosConfig";

const visitorServices = {
    create: async (country, ip, subid, stream, uuid) => {
        try {
            await axiosInstance.post(`/visitor/create`, {
                country, ip, subid, stream, uuid
            });
        } catch (error) {
            return { success: false, error: 'Помилка' };
        }
    },
    adClick: async (ad_id, subid, buyer, visitorUUID) => {
        try {
            await axiosInstance.post(`/visitor/adclick`, {
                ad_id, subid, buyer, visitorUUID
            });
        } catch (error) {
            return { success: false, error: 'Помилка' };
        }
    },
};

export default visitorServices;