import React, { useEffect, useState } from 'react';
import axios from 'axios';

import visitorServices from '../services/visitorServices';
import adServices from '../services/adServices';

import '../styles/ads.css'
import Header from './Header';


const Content = () => {

    const urlParams = new URLSearchParams(window.location.search);

    const [ads, setAds] = useState([]);

    const getGeoInfo = async () => {
        await axios
            .get("https://geolocation-db.com/json/")
            .then((response) => {
                let data = response.data;
                const geoInfo = {
                    ip: data?.IPv4 || data?.IPv6 || null,
                    country: data?.country_name || null,
                };
                visitorServices.create(geoInfo.country, geoInfo.ip, urlParams.get('sub1') || null, urlParams.get('stream') || null, localStorage.getItem('visitor_uuid'));
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const getAds = async (stream) => {
        const response = await adServices.getAdByStream(stream);

        const repeatedAds = [];
        const targetRowCount = 1000;

        const resreshed = [...response];

        for (let i = response.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [resreshed[i], resreshed[j]] = [resreshed[j], resreshed[i]];
        }

        while (repeatedAds.length < targetRowCount) {
            repeatedAds.push(...resreshed);
        }
        setAds(repeatedAds);
    }

    useEffect(() => {
        if (urlParams.get('stream')) {
            getAds(urlParams.get('stream'));
            getGeoInfo();
        }
        if (!localStorage.getItem('visitor_uuid')) {
            localStorage.setItem('visitor_uuid', generateUUID());
        }
        for (let msd = 0; msd < 50; msd++) {
            window.history.pushState({ path: window.location.href }, '', window.location.href);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    const clickToLand = async (ad) => {
        visitorServices.adClick(ad.id, urlParams.get('subid') || 'subid', urlParams.get('sub2') || 'sub2', localStorage.getItem('visitor_uuid'));
        window.location.href = ad.url + '?sub2=' + urlParams.get('sub2') + '&creative_id=' + ad.id;
    }

    const dtime_nums = (offset) => {
        const date = new Date();
        date.setDate(date.getDate() + offset);
        return date.toLocaleDateString();
    }

    const generateUUID = () => {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            const r = (Math.random() * 16) | 0;
            const v = c === 'x' ? r : (r & 0x3) | 0x8;
            return v.toString(16);
        });
    };




    return (

        <>
            <Header />
            <div class="teasers-template-wrapper">
                {ads?.map((ad) => (
                    <div class="teasers-template-2-group-column">
                        <div class="teasers-template-1-small-item-link-container teasers-template-1-gradient-0 pointer" onClick={() => clickToLand(ad)}>
                            <img alt='das' src={`https://api.gurtom.space/img/${ad.creo}`} class="lna-block__image" />
                            <div class="lna-block__content-wrapper">
                                <div class="lna-block__title">
                                    <div class="lna-block__title-text">{ad.text}</div>
                                    <div class="teasers-template-1-small-item-additional-info">
                                        <div class="teasers-template-1-small-item-additional-info-item white-date-icon">
                                            {dtime_nums(0)}
                                        </div>
                                        <div class="teasers-template-1-small-item-additional-info-item white-comments-icon">
                                            {Math.floor(Math.random() * (400 - 100 + 1)) + 100}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </>



    );
};

export default Content;
