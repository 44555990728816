import React from 'react';

const Header = () => {

    const logo = '/images/logo.png';

    return (
        <div>
            <img src={logo} alt='NEWS24' className='img-fluid d-block mx-auto' style={{ maxHeight: '200px', padding: '0' }} />
        </div>
    );
};

export default Header;