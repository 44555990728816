import React from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';
// import Header from './components/Header';
import Content from './components/Content';

const App = () => {
  return (
    <div className='container-fluid'>
      {/* <Header /> */}
      <Content />
    </div>
  );
};

export default App;